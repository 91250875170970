<template>
  <div class="buyCart-container">
      <div class="buy-box">
          <div class="buy-box-select">
              <span>业务类型</span>
              <el-checkbox v-model="goodsType" label="1" @change="selectType">商标服务</el-checkbox>
              <el-checkbox v-model="goodsType" label="2" @change="selectType">专利服务</el-checkbox>
              <el-checkbox v-model="goodsType" label="3" @change="selectType">版权服务</el-checkbox>
         </div>
        <!-- 头部 -->
          <div class="buy-box-top">
              <div class="buy-box-top-div1">商标图样</div>
              <div class="buy-box-top-div2">服务类型</div>
              <div class="buy-box-top-div3">类别</div>
              <div>价格</div>
          </div>
          <!-- 中间列表 -->
          <div class="buy-box-list">
              <div v-for="(item,index) in productList" :key="index">
                <el-checkbox class="flml" v-bind:checked="item.isSelect" v-model="item.isSelect"></el-checkbox>
                  <!-- <input type="checkbox" v-bind:checked="item.isSelect" @change="item.isSelect=!item.isSelect"> -->

                  <div class="buy-box-list-div1">
                      <img :src="item.trademarkImgUrl?item.trademarkImgUrl:require('/static/images/no.png')" alt="">
                  </div>
                  <div class="buy-box-list-div2">{{item.goodsName}}</div>
                  <div class="buy-box-list-div3" v-if="item.topCollectCate" @click="goregister(item.cartCode,item.goodsName,item.trademarkName,item.trademarkImgUrl,item.topCollectCate)">{{item.topCollectCate}}类</div>
                  <div class="buy-box-list-div3" v-else style="color:#333333!important;">无</div>
                  <div class="buy-box-list-div4">￥{{item.orderPrice}}</div>
              </div>
          </div>
          <!-- 全选删除 -->
          <div class="buy-box-bottom">
            <el-checkbox v-model="isSelectAll" v-bind:checked="isSelectAll" @change="selectProduct(isSelectAll)" >全选</el-checkbox>
              <!-- <input type="checkbox" @click="selectProduct(isSelectAll)" v-bind:checked="isSelectAll"> -->
              <!-- <span>全选</span> -->
              <span @click="deleteProduct(isSelectAll)">删除</span>
          </div>
          <!-- 已选商品 -->
          <div class="buy-box-choice">
             已选商品<span>({{getTotal.totalNum}})</span>件
          </div>
          <div style=" clear : both"></div>
          <!-- 合计价钱 -->
          <div class="buy-box-price">
              <span>合计</span>
              <span>RMB {{getTotal.totalPrice}}</span>
          </div>
          <div style=" clear : both"></div>
          <!-- 结算 -->
          <div @click="paymoney" class="buy-box-pay">结算</div>
      </div>
      <div style="clear:both;"></div>
  </div>

</template>

<script>
export default {
  name: 'buyCart',
  data(){
      return{
          goodsType:[],
          productList:[],
          type:1,
          order:'',
          personInfo:{}
      }
  },
  components: {
  },
  computed:{
    //    全选
      isSelectAll:{
        get:function(){
            if(this.productList !=0){
                return this.productList.every(function (val) { return val.isSelect});
            }else{
                return false;
            }
          },
          set: function(){
             if(this.productList !=0){
                return this.productList.every(function (val) { return val.isSelect});
            }else{
                return false;
            }
          }
        },
        // 获取所有勾选的数量和价格
        getTotal:function(){
            var prolist = this.productList.filter(function (val) { return val.isSelect}),
            totalPri = 0;
            for (var i = 0;i< prolist.length; i++) {
                totalPri+=Number(prolist[i].orderPrice);
            }
            return {totalNum:prolist.length,totalPrice:totalPri}
        }
  },
  methods:{
      tip(type,msg){
          return this.$message({
              type:type,
              message:msg,
              showClose:true,
              duration:2500
          })
      },
    //   改变勾选的状态
      selectProduct:function(_isSelect){
        for (var i = 0; i< this.productList.length; i++) {
            this.productList[i].isSelect = !_isSelect;
        }
      },
      // 删除数据
      deleteProduct:function(){
                var prolist = this.productList.filter(function (val) { return val.isSelect});
                if(prolist.length == 0){
                    this.tip('error','请先选择商品')
                    return false;
                }
                var arr = [];
                for (var i = 0;i< prolist.length; i++) {
                    var obj = {}
                    obj.cartCode= prolist[i].cartCode;
                    arr.push(obj)
                }
               
                this.$http.post(this.GLOBAL.new_url+'/trademark/service/cart/delCart',{
                    cartVOList:arr
                },{
                    headers:{
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                    if(res.data.code == "200"){
                        this.productList=this.productList.filter(function (item) {return !item.isSelect});
                        this.tip('success',res.data.msg);
                    }
                })
      },
            goregister(val,val2,val3,val4,val5){
                this.$router.push({
                    path:'/recome',
                    query:{
                        shop_id:val,
                        goodsName:val2,
                        name:val3,
                        url:val4,
                        oneCate:val5
                    }
                })
            },
            paymoney(){
                var prolist = this.productList.filter(function (val) { return val.isSelect});
                if(prolist.length == 0){
                    this.$alert('请选择要购买的商品','温馨提醒',{
                        confirmButtonText: '确定'
                    })
                    return false
                }
                // var agentType = '';
                // if(this.$cookie.getCookie('user_type') == "3"){
                //     agentType = 1
                // }else{
                //     agentType = this.$cookie.getCookie('user_type');
                // }
                var arr2 = [];
                for (var i = 0;i< prolist.length; i++) {
                    arr2.push(prolist[i].cartCode);
                }
                // console.log(arr2)
                   this.$http.post(this.GLOBAL.new_url+'/transactionv2/webapi/shoppingCartCreateOrders',{
                        cartCodeList:arr2
                    },{
                    headers:{
                        'X-User-Code':this.$cookie.getCookie('u_id'),
                        AuthToken:this.$cookie.getCookie('token'),
                        'X-Request-Origin':'xcx'
                    }
                                }).then(res=>{
                        // console.log(res)
                    if(res.data.code == "200"){
                            var a = '';
                        for(var i =0;i<res.data.data.length;i++){
                                    a+=res.data.data[i].orderCode+','
                            }
                            a =  a.substring(0,a.length-1);
                        this.$router.push({
                            path:'/checkOrder',
                            query:{
                            order:a
                            }
                        })
                        }else{
                            this.tip('error',res.data.msg)
                        }
                    })
            },
            getcartData(){
                this.$http.post(this.GLOBAL.new_url+'/trademark/service/cart/queryCartByCode',{
                    userCode:this.$cookie.getCookie('u_id'),
                    goodsType:this.goodsType.join(",")
                    },{
                                headers:{
                                    AuthToken:this.$cookie.getCookie('token'),
                                    'X-Request-Origin':'xcx'
                                }
                                            }).then(res=>{
                        // console.log(res)
                        if(res.data.code == "200"){
                            var list = [];
                            list = res.data.data;
                            for(var i = 0;i<list.length;i++){
                            this.productList = this.productList.concat(list[i].cartVOList);
                            }
                            var _this=this;
                            this.productList.map(function (item) {
                                _this.$set(item, 'isSelect', false);
                            })
                            // console.log(this.productList)
                            
                        }
                    
                    })
            },
            getPerson(){
                this.$http.post(this.GLOBAL.new_url+'/user/service/getPersonInfo',{
                    userCode:this.$cookie.getCookie('u_id')
                    },{
                                headers:{
                                    AuthToken:this.$cookie.getCookie('token'),
                                    'X-Request-Origin':'xcx'
                                }
                                            }).then(res=>{
                    // console.log(res)
                    if(res.data.code== "200"){
                    this.personInfo = res.data.data;
                    }

                })
            },
            selectType(){
                this.productList = [];
                this.getcartData()
            }
  },
  mounted(){
    //   获取数据
        this.getcartData();
        this.getPerson();
  }
}

</script>

<style scoped>
    .buy-box{
        width: 1015px;
        float: left;
        /* border-top:1px solid #d8d8d8; */
        margin: 12px 0 0 16px;
        background-color: #fff;
        padding-top: 28px;
        box-sizing: border-box;
        min-height: 950px;
    }
    .buy-box-select{
        margin: 0 0 30px 83px;
    }
    .buy-box-select>span{
        font-size: 14px;
        margin-right: 15px;
    }
    .buy-box-top{
        width: 870px;
        height: 36px;
        line-height: 36px;
        border:1px solid #eee;
        margin-left: 83px;
        background-color: #fafafa;
        box-sizing: border-box;
    }
    .buy-box-top>div{
        width: 200px;
        float: left;
        text-align: center;
        font-family: "PingFangSC";
        font-size: 14px;
        font-weight: 600;
        color: #333333;
        margin-left: 17px;
    }
    .buy-box-top>div:nth-of-type(1){
        margin-left: 23px;
    }
    .buy-box-top>div:nth-of-type(4){
        margin-left: 8px;
    }
    .buy-box-list {
      margin-left: 83px;
      width: 870px;
    }
    .buy-box-list>div{
        height: 144px;
        line-height: 144px;
        border: 1px solid #eee;
        border-top: none;
    }
    .buy-box-list>div>div{
        float: left;
        width: 210px;
        height: 120px;
        text-align: center;
        font-family: "PingFangSC";
        font-size: 14px;
        font-weight: 100;
        color: #333333;
    }
    .buy-box-list>div>input{
        width: 16px;
        height: 16px;
        margin-top: 64px;
        float: left;
        cursor: pointer;
        margin-left: 12px;
    }
    .buy-box-list-div1 {
      margin-top: 10px;
    }
    .buy-box-list-div1>img{
        width: 120px;
        height: 120px;
        border:1px solid #eeeeee;
    }
    .buy-box-bottom{
        width: 870px;
        margin-top: 20px;
        margin-left: 95px;
    }
    .buy-box-bottom>input{
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-right: 10px;
        cursor: pointer;
    }
    .buy-box-bottom>span{
        font-family: "PingFangSC";
        font-size: 14px;
        font-weight: 100;
        color: #333333;
        float: right;
        margin-right: 12px;
    }
    .buy-box-bottom>span:nth-child(2) {
      color: #EB5E00;
      cursor: pointer;
    }
    .buy-box-choice {
      float: right;
      margin-right: 60px;
      margin-top: 36px;
      font-family: "PingFangSC";
      font-size: 15px;
      color: #333333;
    }
    .buy-box-choice > span {
      color: #EB5E00;
    }
    .buy-box-price{
        font-family: "PingFangSC";
        font-size: 14px;
        color: #333333;
        height: 33px;
        line-height: 33px;
        margin-top: 13px;
        float: right;
    }
    .buy-box-list-div3{
        width: 213px;
        color: #4a90e2!important;
        cursor: pointer;
    }
    .buy-box-price>span:nth-of-type(1){
        color: #EB5E00;
    }
    .buy-box-price>span:nth-of-type(2){
        margin:0 60px 0 30px;
        font-family: "PingFangSC";
        font-size: 20px;
        font-weight: 500;
        color: #333333;
    }
    .buy-box-price>span:nth-of-type(3){
        font-family: "PingFangSC";
        font-size: 24px;
        font-weight: 600;
        letter-spacing: -1.8px;
        color: #333333;
    }
    .buy-box-pay{
        width: 363px;
        height: 54px;
        border-radius: 4px;
        border-width: 1px;
        border-image: linear-gradient(to top, #ba6100 97%, #dc773b 2%);
        background-image: linear-gradient(to bottom, #f79000, orange), linear-gradient(to top, #ba6100 97%, #dc773b 2%);
        background-origin: border-box;
        background-clip: content-box, border-box;
        font-family: "PingFangSC";
        font-size: 14px;
        line-height: 54px;
        text-align: center;
        color: #ffffff;
        margin: 24px 0 114px 592px;
        cursor: pointer;
    }
    
  .flml {
    margin-right: 0px !important;
    float: left !important;
    margin-left: 12px !important;
  }
</style>
<style >
   .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #EB5E00;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #EB5E00;
    border-color: #EB5E00;
  }
</style>
